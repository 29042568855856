import React from "react";
import {
	Table,
	TableContainer,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	Paper,
} from "@mui/material";
import "./BundleTable.scss";

export const BundleTable = ({ components }) => {
	
	if (!Array.isArray(components) || components.length === 0) return null;

	const BundleTableBody = () => (
		<TableBody>
			{components.map((component) => (
				<TableRow key={component?.id}>
					<TableCell>{component?.product?.sku}</TableCell>
					<TableCell>{component?.quantity}</TableCell>
				</TableRow>
			))}
		</TableBody>
	);

	const BundleTableHeader = () => (
		<TableHead>
			<TableRow>
				<TableCell colSpan={2} align="center">
					Bundle
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>SKU</TableCell>
				<TableCell>Qty</TableCell>
			</TableRow>
		</TableHead>
	);

	return (
		<TableContainer
			sx={{ minWidth: "180px", align: "center", overflow: "auto", border: "1px solid #ccc" }}
			component={Paper}
		>
			<Table aria-labelledby={"bundle-table"} className="bundle-table">
				<BundleTableHeader />
				<BundleTableBody />
			</Table>
		</TableContainer>
	);
};
