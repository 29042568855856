import { Auth } from "aws-amplify";
import axios from "axios";
import rateLimit from "axios-rate-limit";
import history from "./history";

const authenticatedRequest = rateLimit(axios.create({
	baseURL: process.env.REACT_APP_API_URL,
}), { maxRPS: 3 });
authenticatedRequest.getMaxRPS();


authenticatedRequest.interceptors.request.use(
	async (config) => {
		const data = await Auth.currentSession();
		config.headers.Authorization = `Bearer ${data.accessToken.jwtToken}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

authenticatedRequest.interceptors.request.use(
	(request) => {
		if (!request.headers) {
			request.headers = {};
		}
    
		return request;
	},
	(error) => {
		return Promise.reject(error);
	},
);

authenticatedRequest.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// 404 status is handled via MainPanel
		if ((error.response && ([401, 403].includes(error.response.status))) ||
		error.message === "Network Error") {
			history.replace("/error");
		} else {
			return Promise.reject(error);
		}
	},
);

export default authenticatedRequest;
