import React from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
} from "@mui/material";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function FrozenPage() {

	const PageBody = () => (
		<>
			<Typography variant="body1" mb={2}>
				Dear Seller,<br/><br/>Your account has been suspended due to an overdue invoice that has not been paid yet. Please check your email for communications we have previously sent. Please settle your invoice as soon as possible to resume operations.
			</Typography>				
			<Typography variant="body1">
				For more information, please send a ticket to <Box component="span" className="external-link">finance@bezos.ai</Box>
			</Typography>
		</>
	);

	return (
		<Box id="frozen-page">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<PageHeading pageTitle={"Your account has been suspended"} sx={{ mb: 1 }}/>
						<PageBody/>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
}
