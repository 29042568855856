import React, {
	useState,
	useEffect
} from "react";
import "./DebugView.scss";
import { Auth } from "aws-amplify";
import SellerDependentView from "../Shared/SellerDependentView";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import {
	Box,
	Grid,
	Paper,
	Tooltip
} from "@mui/material";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function Debug({ seller, userdata }) {

	const [token, setToken] = useState("");
	const [refreshToken, setRefreshToken] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		Auth.currentSession()
			.then(data => {
				setToken(data.accessToken.jwtToken);
				setIsLoading(false);
			});
	}, [refreshToken]);

	const onClickCopyToken = async () => {
		setIsLoading(true);
		setRefreshToken(prev => !prev);
		await navigator.clipboard.writeText(token);
	};

	const PageBody = () => (
		<LoadableContent isLoading={isLoading} marginBottom={5}>
			<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
				<Grid item xs={12}>
					<Tooltip
						title="Click to copy"
						placement="top"
					>
						<Box
							className="debug-token"
							onClick={() => onClickCopyToken()}
						>
							{token}
						</Box>
					</Tooltip>
				</Grid>
			</Paper>
		</LoadableContent>
	);

	return (
		<Box id="debug">
			<PermissionDependentView userdata={userdata} permission="view_debug_page">
				<SellerDependentView seller={seller}>
					<Grid container>
						<PageHeading pageTitle={"Access Token"}/>
						<PageBody />
					</Grid>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);

}
