import React, { useState } from "react";
import {
	Box,
	Dialog,
	IconButton,
	Button,
	Card,
	CardActionArea,
	CardMedia,
	DialogContent,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/PreviewOutlined";
import LightTooltip from "../LightTooltip";

export const ProductImage = ({
	buttonType,
	isVisible,
	onClick,
	onClose,
	imageURL,
}) => {

	const [isError, setIsError] = useState(false);
	
	const imageNotFound = "/imgs/image-not-found.svg";
	
	const handleImageError = () => {
		setIsError(true);
	};

	const TriggerIcon = () => (
		<LightTooltip
			title={"Click to preview product"}
			disableHoverListener={false}
			arrow
		>
			<IconButton 
				onClick={onClick}
				sx={{ p: 0 }}
				data-testid="trigger-icon"
			>
				<PreviewIcon fontSize="large" />
			</IconButton>
		</LightTooltip>
	);

	const TriggerButton = () => (
		<Button
			variant="outlined"
			onClick={onClick}
			color="primary"
			data-testid="trigger-button"
		>
			View Image
		</Button>
	);

	const TriggerThumbnail = () => (
		<Card sx={{ maxWidth: 50 }} data-testid="trigger-thumbnail">
			<CardActionArea onClick={isError ? null : onClick} disabled={isError}>
				<CardMedia
					component="img"
					height="50"
					image={isError ? imageNotFound : imageURL}
					alt={isError ? "Image not available" : "Preview product"}
					title={isError ? "Image not available" : "Preview product"}
					loading="lazy"
					onError={handleImageError}
				/>
			</CardActionArea>
		</Card>
	);

	const ModalTrigger = () => {
		if (buttonType === "icon") return <TriggerIcon />;
		if (buttonType === "button") return <TriggerButton />;
		if (buttonType === "thumbnail") return <TriggerThumbnail />;
		return <TriggerButton />;
	};

	const ModalImage = () => (
		<Dialog
			open={isVisible}
			onClose={onClose}
			keepMounted
			fullWidth
			maxWidth="sm"
			PaperProps={{
				sx: {
					boxShadow: 3,
				},
			}}
			BackdropProps={{
				sx: {
					backgroundColor: "rgba(0, 0, 0, 0.3)",
				},
			}}
		>
			<DialogContent
				sx={{
					display: "flex",
					justifyContent: "center",
					backgroundColor: "transparent",
					p: 0,
				}}
			>
				<Box
					component="img"
					src={isError ? imageNotFound : imageURL}
					alt="Product image"
					title="Product image"
					loading="lazy"
					sx={{ width: "100%", height: "auto" }}
					onError={handleImageError}
				/>
			</DialogContent>
		</Dialog>
	);

	return (
		<>
			<ModalTrigger />
			<ModalImage />
		</>
	);
};
