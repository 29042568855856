import React, {
	useState,
	useEffect,
	useMemo,
	useRef
} from "react";
import { CustomRouter as Router } from "./utils/router";
import history from "./utils/history";
import "@aws-amplify/ui-react/styles.css";
import "./scss/main.scss";
import { 
	Authenticator,
	useAuthenticator } 
	from "@aws-amplify/ui-react";
import StatusBar from "./components/Shared/StatusBar";
import LeftHandMenu from "./components/Shared/LeftHandMenu";
import MainPanel from "./components/Shared/MainPanel";
import { 
	LightTheme, 
	DarkTheme 
} from "./utils/baseTheme";
import {
	ThemeProvider,
	StyledEngineProvider,
	Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import DataProvider from "./dataprovider/dataProvider";
import authenticatedRequest from "./utils/authenticatedRequest";
import { useSelector } from "react-redux";
import { BackToTop } from "./components/Shared/BackToTop/BackToTop";
import BezosSignIn from "./components/BezosSignIn/BezosSignIn";

export const DataContext = React.createContext();

function App() {

	const modeDefault = JSON.parse(localStorage.getItem("Theme"));
	const [state, setState] = useState({
		open: false,
		marginTopValue: 0,
		darkMode: modeDefault || false,
		isLeftMenuVisible: true,
	});

	const setTheme = () => {

		localStorage.setItem("Theme", JSON.stringify(!state.darkMode));

		setState(previousState => {
			return {
				...previousState,
				darkMode: !state.darkMode,
			};
		});
	};

	const [userdata, setUserdata] = useState({
		permissions: new Map(),
	});
	const [bannerdata, setBannerdata] = useState({});

	const getDrawerStatus = (props) => {
		setState(previousState => {
			return {
				...previousState,
				open: props
			};
		});
	};

	const drawerWidth = 240;
	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);

	const isAlertBarVisible = useSelector(state => state.isAlertBarVisible);
	useEffect(() => {
		if (isAlertBarVisible === true) {
			setState(previousState => {
				return {
					...previousState,
					marginTopValue: "55px",
				};
			});
		} else {
			setState(previousState => {
				return {
					...previousState,
					marginTopValue: 0,
				};
			});
		}
	}, [isAlertBarVisible]);

	const dataProvider = useRef(null);
	useMemo(() => {
		dataProvider.current = new DataProvider(authenticatedRequest);
	}, []);

	const loadUserData = async () => {
		dataProvider.current.getUserData()
			.then(response => {
				const permissions = response.data.permissions;
				if (permissions) {
					response.data.permissions = new Map(Object.entries(permissions));
				}

				setUserdata(response.data);
			});
	};

	const loadBannerData = async () => {
		dataProvider.current.getAlertBanner()
			.then(response => {
				setBannerdata(response.data);
			});
	};

	const { user } = useAuthenticator((context) => [context.user]);

	useEffect(() => {
		loadUserData();
		loadBannerData();
	}, [dataProvider, user]);

	const setSellerAccountActive = (status) => {
		setState(previousState => {
			return {
				...previousState,
				isLeftMenuVisible: status,
			};
		});
	};

	const formFields = {
		signIn: {
			username: {
				placeholder: "Enter username...",
				label: "Username",
				margin: "normal",
				size: "small"
			},
			password: {
				placeholder: "Enter password...",
				label: "Password",
				margin: "normal",
				size: "small"
			}
		}
	};

	const components = {
		SignIn: BezosSignIn
	};

	return (
		<Authenticator
			hideSignUp={true}
			components={components}
			formFields={formFields}
			variation="modal">
			{({signOut}) => (
				<DataContext.Provider value={{ dataProvider: dataProvider.current }}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={state.darkMode ? DarkTheme : LightTheme }>
							<Router history={history}>
								<Box 
									sx={{ 
										display: "flex",
										backgroundColor: theme => theme.palette.background.main,
										minHeight: "100vh",
										height: "100%",
										width: "100%",
									}}
								>
									<CssBaseline />
									<StatusBar
										marginTop={state.marginTopValue}
										onHandleDrawer={getDrawerStatus}
										isDrawerVisible={state.open}
										drawerWidth={drawerWidth}
										userdata={userdata}
										bannerdata={bannerdata}
										check={state.darkMode}
										onSignOut={signOut}
										change={() => setTheme()}
										isLeftMenuVisible={state.isLeftMenuVisible}
									/>
									<LeftHandMenu
										marginTop={state.marginTopValue}
										onHandleDrawer={getDrawerStatus}
										isDrawerVisible={state.open}
										drawerWidth={drawerWidth}
										userdata={userdata}
										isLeftMenuVisible={state.isLeftMenuVisible}
									/>
									<Box
										component="main"
										sx={{ 
											flexGrow: 1, 
											p: 3, 
											marginTop: state.marginTopValue, 
											marginBottom: "3rem",
										}}
									>
										<DrawerHeader />
										<MainPanel
											userdata={userdata}
											setSellerAccountActive={setSellerAccountActive}
										/>
										<BackToTop />
									</Box>
								</Box>
							</Router>
						</ThemeProvider>
					</StyledEngineProvider>
				</DataContext.Provider>
			)}
		</Authenticator>
	);
}

export default App;
