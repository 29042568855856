export const capitalizeFirstLetter = (s) => {
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeFirstLetterOfEachWord = (word) => {
	return word.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const camelToSnakeCase = (str) => {
	return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const camelCase = (str) => {
	const words = str.toLowerCase().split(" ");
	for (let i = 1; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}
	return words.join("");
};

export function cleanString(string) {
	if (!string) return;
	var words = string.split("_");
	var convertedString = words.map(function(word) {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}).join(" ");
	return convertedString;
}

export const removeUnderScore = (s) => {
	return s.replace(/_/g, " ");
};

export const sanitizeFilterInput = (input) => {
	return input.replace(/[^"'.a-zA-Z 0-9_-]/g, "").toLowerCase().split(" ").filter(Boolean);
};
