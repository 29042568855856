import React, {
	useContext,
	useState,
	useEffect,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
} from "@mui/material";
import { DataContext } from "../../App";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { getLast30DaysDates } from "../../utils/date";
import DataTable from "../Shared/Table/Table";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import Modal from "../Shared/Modals/Modal";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function LateDeliveries({ seller }) {
    
	const context = useContext(DataContext);
	const [state, setState] = useState({
		isLoading: null,
		standardDeliveries: [],
		nextDayDeliveries: [],
		todaysDeliveries: [],
		isVisible: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const tableHeaders = [	
		{
			key: "order_number",
			label: "Order Number",
		},
		{
			key: "custom_component_1",
			label: "Status",
			component: (rowData) => <StatusBox 
				status={rowData.data.status}
				statusType="courier"
			/>,
		},
		{
			key: "courier_service",
			label: "Courier Service",
		},
		{
			key: "tracking_number",
			label: "Tracking Number",
		},
		{
			key: "last_checkpoint_message",
			label: "Last Checkpoint Message",
		},
		{
			key: "late_by_days",
			label: "Late By (Days)",
		},
	];

	useEffect(() => {

		const getLateDeliveries = async () => {
	
			if (!seller) return;
	
			setState((previousState) => ({
				...previousState,
				isLoading: true,
			}));
	
			const { startDate, endDate } = getLast30DaysDates();
	
			await context.dataProvider
				.getLateDeliveries(seller, startDate, endDate)
				.then((res) => {
					const deliveries = res.data;
					const standardDeliveries = deliveries.filter(
						(d) => d.late_by_days > 5
					);
					const nextDayDeliveries = deliveries.filter(
						(d) =>
							d.late_by_days > 2 &&
							d.late_by_days <= 5
					);
					const todaysDeliveries = deliveries.filter(
						(d) => d.late_by_days == 1
					);

					setState((prevState) => ({
						...prevState,
						isLoading: false,
						standardDeliveries,
						nextDayDeliveries,
						todaysDeliveries
					}));
				})
				.catch(() => {
					setState((prevState) => ({
						...prevState,
						isLoading: false,
						standardDeliveries: [],
						nextDayDeliveries: [],
						todaysDeliveries: []
					}));
				});
		};
		getLateDeliveries();
	}, [context.dataProvider, seller]);
	
	const handleShowTodaysDeliveries = () => {
		setState((prevState) => ({
			...prevState,
			isVisible: !state.isVisible,
			isModalOpen: false,
		}));
	};

	useEffect(() => {
		if (state.todaysDeliveries.length > 0) {

			const count = state.todaysDeliveries.length;

			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
					modalBodyTitle: "Today's Late Deliveries",
					modalBodyMessage1: `You have ${count} new late deliveries today.`,
					modalBodyMessage2: "",
					modalButtonVariant1: "contained",
					modalButtonText1: "See Details",
					modalButtonActionType1: "function",
					modalButtonAction1: () => handleShowTodaysDeliveries(),
					modalButtonVariant2: "",
					modalButtonText2: "",
					modalButtonActionType2: "",
					modalButtonAction2: "",
					modalButtonColor2: "",
				};
			});
		}
	}, [state.todaysDeliveries]);

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				isVisible: true,
			};
		});
	};

	const StandardDelivery = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Typography component="h2" variant="h5" sx={{ mb: 2 }}>
                Standard Delivery
				<RecordsTotal total={state.standardDeliveries.length} />
			</Typography>
			<DataTable
				data={state.standardDeliveries.sort((a, b) => a.created_at > b.created_at ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={false}
				usePagination={false}
				itemType=""
			/>
		</Paper>
	);

	const NextDayDelivery = () => (
		<Paper sx={{ p: 2, width: "100%", mt: 2 }} elevation={2}>
			<Typography component="h2" variant="h5" sx={{ mb: 2 }}>
                Next Day Delivery
				<RecordsTotal total={state.nextDayDeliveries.length} />
			</Typography>
			<DataTable
				data={state.nextDayDeliveries.sort((a, b) => a.created_at > b.created_at ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={false}
				usePagination={false}
				itemType=""
			/>
		</Paper>
	);

	const TodayDelivery = () => (
		state.todaysDeliveries?.length > 0 &&
		<Box className="container">
			<Box className={`${state.isVisible ? "visible" : "hidden"}-alert`}>
				<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
					<Typography component="h2" variant="h5" sx={{ mb: 2 }}>
						{"Today's Delivery"}
						<RecordsTotal total={state.todaysDeliveries.length} />
					</Typography>
					<DataTable
						data={state.todaysDeliveries.sort((a, b) => a.created_at > b.created_at ? -1 : 1)}
						headers={tableHeaders}
						dateHeaders={[]}
						isLoading={false}
						usePagination={false}
						itemType=""
					/>
				</Paper>
			</Box>
		</Box>
	);

	return (
		<Box id="late-deliveries">
			<LoadableContent isLoading={state.isLoading} marginBottom={4}>
				<Grid container>
					<Grid item xs={12}>
						<PageHeading pageTitle={"Late Deliveries"}/>
						<TodayDelivery />
						<StandardDelivery />
						<NextDayDelivery />
						<Modal
							onModalClose={onModalClose}
							isModalOpen={state.isModalOpen}
							modalBodyTitle={state.modalBodyTitle}
							modalBodyMessage1={state.modalBodyMessage1}
							modalButtonVariant1={state.modalButtonVariant1}
							modalButtonText1={state.modalButtonText1}
							modalButtonActionType1={state.modalButtonActionType1}
							modalButtonAction1={state.modalButtonAction1}
							modalButtonVariant2={state.modalButtonVariant2}
							modalButtonText2={state.modalButtonText2}
							modalButtonActionType2={state.modalButtonActionType2}
							modalButtonAction2={state.modalButtonAction2}
							modalButtonColor2={state.modalButtonColor2}
						/>
					</Grid>
				</Grid>
			</LoadableContent>
		</Box>
	);

}