import React from "react";
import { formatLastUpdatedDate } from "../../../utils/date.js";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { Box } from "@mui/material";
import { capitalizeFirstLetter } from "../../../utils/text";

export function LastWarehouseUpdateDetails(props) {
	if (!props.details.last_synced) return null;

	const lastSyncedDate = new Date(props.details.last_synced);

	const text = capitalizeFirstLetter(formatLastUpdatedDate(lastSyncedDate));
	const syncErrors = props.details.sync_errors && props.details.sync_errors.length > 0
		? capitalizeFirstLetter(props.details.sync_errors)
		: null;

	return syncErrors ? (
		<LightTooltip title={syncErrors} arrow>
			<Box component="span">{text}⚠️</Box>
		</LightTooltip>
	) : (
		text
	);
}
