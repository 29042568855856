export const getStatusColor = (status) => {
	switch (status.toLowerCase()) {
	case "despatched":
		return "seagreen";
	case "returned":
		return "seagreen";
	case "picked":
		return "cornflowerblue";
	case "sent to warehouse":
		return "cornflowerblue";
	case "processed":
		return "cornflowerblue";
	case "processing":
		return "cornflowerblue";
	case "inforeceived":
		return "slateblue";
	case "intransit":
	case "in transit":
		return "cornflowerblue";
	case "outfordelivery":
		return "cornflowerblue";
	case "transit":
		return "cornflowerblue";
	case "pickup":
		return "cornflowerblue";
	case "delivered":
		return "seagreen";
	case "attemptfail":
	case "attempt failed":
		return "rgb(161, 51, 51)";
	case "exception":
		return "rgb(161, 51, 51)";
	case "cancelled":
		return "#cccccc";
	case "pending":
		return "#cccccc";
	case "expired":
		return "#cccccc";
	case "new":
		return "cornflowerblue";
	case "held":
		return "cornflowerblue";
	case "onbackorder":
		return "orange";
	case "label created":
		return "#d4af37";
	case "fraudrisk":
		return "rgb(161, 51, 51)";
	case "awaitingconfirmation":
		return "rgb(161, 51, 51)";
	case "error processing report":
		return "rgb(161, 51, 51)";
	case "ready to download":
		return "rgb(78, 159, 61)";
	case "notscanned":
		return "#d4af37";
	case "draft":
		return "orange";
	case "awaitingscan":
		return "#d4af37";
	case "lostnotscanned":
		return "rgb(161, 51, 51)";
	case "lostintransit":
		return "rgb(161, 51, 51)";
	case "returningtosender":
		return "rgb(161, 51, 51)";
	case "availableforcustomercollection":
		return "rgb(78, 159, 61)";
	case "awaitingdelivery":
		return "rgb(161, 51, 51)";
	case "bookedin":
		return "rgb(78, 159, 61)";
	case "uploadpending":
		return "orange";
	default:
		return "grey";
	}
};

export const getClaimsStatusColor = (status) => {
	switch (status.toLowerCase()) {
	case "unsuccessful":
		return "rgb(161, 51, 51)";
	case "in progress":
		return "orange";
	case "successful":
		return "seagreen";
	default:
		return "grey";
	}
};

export const getASNStatusColor = (status) => {
	switch (status.toLowerCase()) {
	case "delivered":
		return "cornflowerblue";
	case "awaitingdelivery":
		return "rgb(161, 51, 51)";
	case "partiallybooked":
		return "rgb(204, 163, 0)";
	case "bookedin":
		return "rgb(50, 103, 38)";
	case "bookedin-partial":
		return "rgb(78, 159, 61)";
	case "new":
		return "cornflowerblue";
	case "uploadpending":
		return "cornflowerblue";
	case "complete":
		return "rgb(78, 159, 61)";
	case "draft":
		return "orange";
	default:
		return "grey";
	}
};

export const getASNStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "awaitingdelivery":
		return "Awaiting Delivery";
	case "bookedin":
		return "Booked In";
	case "partiallybooked":
		return "Partially Booked";
	case "bookedin-partial":
		return "Booked In Partial";
	case "delivered":
		return "Delivered";
	case "cancelled":
		return "Cancelled";
	case "draft":
		return "Draft";
	case "new":
		return "New";
	case "complete":
		return "Complete";
	case "uploadpending":
		return "Upload Pending";
	default:
		return status.toLowerCase();
	}
};

export const getASNDeliverySlotStatusLabel = (item) => item;

export const getASNDeliverySlotStatusColor = (status) => {
	switch (status.toLowerCase()) {
	case "required":
		return "rgb(161, 51, 51)";
	case "pending":
		return "cornflowerblue";
	case "requested":
		return "cornflowerblue";
	case "approved":
		return "seagreen";
	case "n/a":
		return "grey";
	default:
		return "grey";
	}
};

export const getInventoryStatusColor = (status) => {
	switch (status.toLowerCase()) {
	case "active":
		return "seagreen";
	case "pending":
		return "cornflowerblue";
	case "awaitingverification":
		return "rgb(212, 175, 55)";
	case "rejected":
		return "rgb(161, 51, 51)";
	case "undefined":
		return "rgb(204, 204, 204)";
	case "uploadpending":
			return "orange";
	case "hidden":
			return "grey";
	default:
		return "grey";
	}
};

export const getOrderStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "new":
		return "New";
	case "onbackorder":
		return "On back order";
	case "fraudrisk":
		return "Fraud risk";
	case "awaitingconfirmation":
		return "Awaiting confirmation";
	case "despatched":
		return "Despatched";
	case "processed":
		return "Processed";
	case "processing":
		return "Processing";
	case "returned":
		return "Returned";
	case "delivered":
		return "Delivered";
	case "cancelled":
		return "Cancelled";
	case "pending":
		return "Pending";
	case "availableforpickup":
		return "Available for pickup";
	case "picked":
		return "Picked";
	case "held":
		return "On hold";
	case "sent to warehouse":
		return "Sent to warehouse";
	case "uploadpending":
		return "Upload Pending";	
	default:
		return status.toLowerCase();
	}
};

export const getCourierStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "inforeceived":
		return "Info received";
	case "intransit":
		return "In transit";
	case "transit":
		return "In transit";
	case "outfordelivery":
		return "Out for delivery";
	case "pickup":
		return "Out for delivery";
	case "delivered":
		return "Delivered";
	case "attemptfail":
		return "Failed attempt";
	case "exception":
		return "Exception";
	case "pending":
		return "Pending";
	case "expired":
		return "Expired";
	case "availableforpickup":
		return "Available for pickup";
	case "notscanned":
		return "Not scanned";
	case "awaitingscan":
		return "Awaiting scan";
	case "returningtosender":
		return "Returning to sender";
	case "availableforcustomercollection":
		return "Available for customer collection";
	case "lostnotscanned":
		return "Lost not scanned";
	case "lostintransit":
		return "Lost in transit";
	default:
		return status.toLowerCase();
	}
};

export const getReportStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "processing":
		return "Processing";
	case "ready to download":
		return "Ready to download";
	case "error processing report":
		return "Error processing report";
	default:
		return status.toLowerCase();
	}
};

export const productStatusPending = "pending",
	productStatusRejected = "rejected",
	productStatusActive = "active",
	productStatusAwaitingVerification = "awaitingverification",
	productStatusUndefined = "undefined";

export const getInventoryStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case productStatusActive:
		return "Active";
	case productStatusPending:
		return "Pending";
	case productStatusAwaitingVerification:
		return "Awaiting Verification";
	case productStatusRejected:
		return "Rejected";
	case "uploadpending":
		return "Upload Pending";			
	case productStatusUndefined:
		return "Undefined";
	default:
		return status.toLowerCase();
	}
};
