import React, {
	useState,
	useCallback
} from "react";
import {
	Link,
	useParams
} from "react-router-dom";
import UseFetchData from "../Shared/FetchData/FetchData";
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Button,
	Grid,
	Snackbar,
} from "@mui/material";
import "./GoodsInDetail.scss";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import Modal from "../Shared/Modals/Modal";
import { DataContext } from "../../App";
import ItemDisplay from "../Shared/ViewItems/ItemDisplay";
import { DeliverySlot } from "../Shared/DeliverySlot/DeliverySlot";
import { capitalizeFirstLetter } from "../../utils/text";
import {
	getDisplayDate,
	getDisplayTime,
	convertToISO,
	getISODateTime
} from "../../utils/date";
import { containsItemWithKeyValue } from "../../utils/object";
import { handleAxiosError } from "../../utils/error";
import { getASNDeliverySlotStatus } from "../../utils/deliverySlotStatus";
import { setDocumentTitle } from "../../utils/page";
import { AlertBox } from "../Shared/Alert/Alert";
import DownloadDocument from "../Shared/DownloadDocument/DownloadDocument";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { LastWarehouseUpdateDetails } from "../Shared/LastWarehouseUpdate/LastWarehouseUpdateDetails";

export default function GoodsInDetail({ seller, userdata }) {

	const asn = useParams().id;
	const [state, setState] = useState({
		isVisibleMoreActionsDiv: false,
		isLoading: false,
		alertMessage: "",
		alertSeverity: "",
		showAlert: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalErrorMessage: null,
		isModalOpenDeliverySlot: false,
		ModalDeliverySlotType: "",
		ModalDeliverySlotTitle: "",
	});

	const context = React.useContext(DataContext);
	const { status, results, isLoading } = UseFetchData(useCallback(() => context.dataProvider.getASN(asn), [context.dataProvider, asn]));

	const page_data = {
		asn: {
			asn_id: asn,
			status: `${results.status}`,
			seller: results.seller_code,
			warehouse: results.warehouse_code,
			created_date: results.created_date,
			estimated_delivery: results.estimated_delivery_date,
			delivery_type: results.delivery_type,
			delivery_slot_requests: results.delivery_slot_requests,
			delivery_slot_required: results.delivery_slot_required,
			has_paperwork_available: results.has_paperwork_available,
			carrier: results.carrier,
			tracking_number: results.tracking_number,
			seller_po_reference: results.seller_po_reference,
			comments: results.comments,
			country_of_origin: results.country_of_origin,
			platform_id: results.id,
			inbound_order_number: results.inbound_order_number,
			units_received: results.units_received,
			units_expected: results.units_expected,
			last_synced: results.last_synced,
			sync_errors: results.sync_errors,
		},
		product: {
			items: results.items
		}
	};

	const BackButton = () => {
		return (
			<Button variant="contained" component={Link} to="/asn-summary">
				Go Back
			</Button>
		);
	};

	const onClickMoreActionsButton = () => {
		setState(previousState => {
			return {
				...previousState,
				isVisibleMoreActionsDiv: !previousState.isVisibleMoreActionsDiv
			};
		});
	};

	const MoreActionsDiv = () => {
		return (
			<Box 
				className={`panel${state.isVisibleMoreActionsDiv === true ? "-show" : "-hide"}`}
				sx={{ mb: 1 }}
			>
				<Grid container spacing={1}>
					<Grid item xs={6} md={10} lg={12} xl={12} container spacing={2} mb={1}>
					
						{page_data.asn.has_paperwork_available &&
						<DownloadDocument
							name="ASN Document"
							pageType="detail"
							id={page_data.asn.asn_id}
						/>
						}

						{(userdata.permissions
						&& userdata.permissions.has("approve_asn_delivery_slot_request"))
						&& (containsItemWithKeyValue(page_data.asn.delivery_slot_requests, "status", "sent") ||
								containsItemWithKeyValue(page_data.asn.delivery_slot_requests, "status", "rejected")) &&
						<Grid item xs="auto">
							<ApproveDeliverySlotButton />
						</Grid>}

						{(userdata.permissions
						&& userdata.permissions.has("reject_asn_delivery_slot_request"))
						&& containsItemWithKeyValue(page_data.asn.delivery_slot_requests, "status", "sent") &&
						<Grid item xs="auto">
							<RejectDeliverySlotButton />
						</Grid>}

						{(page_data.asn.delivery_slot_required === true)
						&& (!page_data.asn.delivery_slot_requests) &&
						<Grid item xs="auto">
							<RequestDeliverySlotButton />
						</Grid>}

						{(page_data.asn.status.toLowerCase() === "awaitingdelivery") &&
						<Grid item xs="auto">
							<UpdateASNTrackingInformationButton />
						</Grid>}

					</Grid>
				</Grid>
			</Box>
		);
	};

	const onClickCancelASN = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Cancel ASN",
				modalBodyMessage1: "Are you sure you'd like to cancel this ASN? This action cannot be undone.",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes, Cancel ASN",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickCancelASNModalConfirmation,
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const onClickCancelASNModalConfirmation = async () => {

		// close confirm modal
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "", // ensures 2nd button does not render in modal
			};
		});

		if (!seller) return;

		await context.dataProvider.cancelASN(page_data.asn.platform_id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN successfully cancelled",
						modalBodyMessage1: "This ASN has been cancelled",
						modalBodyMessage2: "Note: It can take a few minutes for these changes to update in your order summary.",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/asn-summary",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN cancellation unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try cancelling the ASN again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Details",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});

	};

	const CancelASNButton = () => {
		return (
			<>
				<Button
					variant="contained"
					onClick={onClickCancelASN}
					color="destructive"
					className="cancel-asn-button"
				>
					Cancel ASN
				</Button>
			</>
		);
	};

	const RequestDeliverySlotButton = () => {
		return (
			<>
				<Button
					variant="outlined"
					color="secondary"
					onClick={onClickRequestDeliverySlot}
				>
					Request Delivery Slot
				</Button>
			</>
		);
	};

	const onClickRequestDeliverySlot = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpenDeliverySlot: true,
				ModalDeliverySlotType: "request",
				ModalDeliverySlotTitle: "Book a Delivery Slot",
			};
		});
	};

	const ApproveDeliverySlotButton = () => {
		return (
			<>
				<Button
					variant="outlined"
					color="secondary"
					onClick={onClickApproveDeliverySlot}
				>
					Approve Delivery Slot
				</Button>
			</>
		);
	};

	const onClickApproveDeliverySlot = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpenDeliverySlot: true,
				ModalDeliverySlotType: "approve",
				ModalDeliverySlotTitle: "Delivery Slot Approved",
			};
		});
	};

	const RejectDeliverySlotButton = () => {
		return (
			<>
				<Button
					variant="outlined"
					color="secondary"
					onClick={onClickRejectDeliverySlot}
				>
					Reject Delivery Slot
				</Button>
			</>
		);
	};

	const onClickRejectDeliverySlot = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpenDeliverySlot: true,
				ModalDeliverySlotType: "reject",
				ModalDeliverySlotTitle: "Reject Delivery Slot",
			};
		});
	};

	const getDeliverySlot = (values, type) => {
		if (values.estimated_delivery_date === "" || type === "") return;

		onModalCloseDeliverySlot();

		const createASNDeliverySlot = async () => {
			await context.dataProvider.createASNDeliverySlot(page_data.asn.asn_id, {
				delivery_slot_date: convertToISO(values.estimated_delivery_date),
				is_customs_cleared: values.is_customs_cleared === "true",
			})
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Delivery Slot Requested",
							modalBodyMessage1: "A delivery slot has been requested for " + getDisplayDate(values.estimated_delivery_date) + ". We will confirm via email when the delivery slot has been booked in.",
							modalButtonText1: "Done",
						};
					});
				})
				.catch((error) => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Delivery Slot Request Failed for ASN " + page_data.asn.asn_id,
							modalErrorMessage: "Error: " + handleAxiosError(error) + ".",
							modalWarningMessage: "Please contact support@bezos.ai to book your Delivery Slot.",
							modalButtonText1: "Close",
						};
					});
				});
		};

		const approveASNDeliverySlot = async () => {
			let date = convertToISO(values.delivery_date);

			let time = "00:00:00";
			if (values.delivery_time === "9am") time = "09:00:00";
			if (values.delivery_time === "12pm") time = "12:00:00";

			date = getISODateTime(date, time);

			const isContainerType = page_data.asn.delivery_type.toLowerCase().includes("container");

			let modalBodyMessage1 = "Approved for " + getDisplayDate(date);
			if (isContainerType) {
				modalBodyMessage1 = "Approved for " + getDisplayTime(date);
			}

			await context.dataProvider.approveASNDeliverySlot(seller, page_data.asn.asn_id, date)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Delivery Slot Approved",
							modalBodyMessage1: modalBodyMessage1,
							modalBodyMessage2: "",
							modalButtonText1: "Done",
						};
					});
				})
				.catch((error) => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Delivery Slot Approval Failed for ASN " + page_data.asn.asn_id,
							modalBodyMessage1: "",
							modalErrorMessage: "Error: " + capitalizeFirstLetter(error.response.data.message) + ".",
							modalWarningMessage: "Please contact support@bezos.ai to book your Delivery Slot.",
							modalButtonText1: "Close",
						};
					});
				});
		};

		const rejectASNDeliverySlot = async () => {
			let date = convertToISO(values.delivery_date);

			let time = "00:00:00";
			if (values.delivery_time === "9am") time = "09:00:00";
			if (values.delivery_time === "12pm") time = "12:00:00";

			date = getISODateTime(date, time);

			await context.dataProvider.rejectASNDeliverySlot(seller, page_data.asn.asn_id, date)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Reject Delivery Slot Successful",
							modalBodyMessage1: "Delivery Date Suggested: " + getDisplayTime(date),
							modalBodyMessage2: "",
							modalButtonText1: "Done",
						};
					});
				})
				.catch((error) => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Reject Delivery Slot Failed for ASN " + page_data.asn.asn_id,
							modalBodyMessage1: "",
							modalErrorMessage: "Error: " + capitalizeFirstLetter(error.response.data.message) + ".",
							modalWarningMessage: "Please contact support@bezos.ai to reject delivery slot.",
							modalButtonText1: "Close",
						};
					});
				});
		};

		if (type === "request") createASNDeliverySlot();
		if (type === "approve") approveASNDeliverySlot();
		if (type === "reject") rejectASNDeliverySlot();

		setState(previousState => {
			return {
				...previousState,
				modalButtonVariant1: "contained",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				isModalOpen: true,
			};
		});

	};

	const onModalCloseDeliverySlot = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpenDeliverySlot: false,
			};
		});
	};

	const UpdateASNTrackingInformationButton = () => {
		return (
			<>
				<Button
					variant="outlined"
					onClick={onClickUpdateASNTrackingInformation}
					color="secondary"
				>
					Add Tracking Information
				</Button>
			</>
		);
	};

	const onClickUpdateASNTrackingInformation = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Add Tracking Information",
				modalBodyMessage1: "Please provide the tracking number of the shipment",
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalBodyInputFields: {
					courier: {
						label: "Carrier",
						type: "text",
					},
					tracking_number: {
						label: "Tracking Number",
						type: "text",
					},
				},
				modalButtonVariant1: "outlined",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Confirm",
				modalButtonActionType2: "function",
				modalButtonAction2: onClickUpdateASNTrackingInformationModal,
				modalButtonColor2: state.modalButtonColor2,
				isModalOpen: true,
			};
		});
	};

	const onClickUpdateASNTrackingInformationModal = async (values) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "",
				modalBodyInputFields: {},
			};
		});

		if (!seller) return;

		await context.dataProvider.updateASNTrackingInformation(page_data.asn.platform_id, values)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Tracking information successfully updated",
						modalBodyMessage1: "",
						modalBodyMessage1Data: [],
						modalBodyMessage2: "Note: It can take a few minutes for these ASN details to be updated.",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Summary",
						modalButtonActionType1: "link",
						modalButtonAction1: "/asn-summary",
					};
				});
			})
			.catch(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Tracking information update was unsuccessful",
						modalBodyMessage1: "Something went wrong.",
						modalBodyMessage2: "Please try the update again, if this problem continues then contact support@bezos.ai",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to ASN Summary",
						modalButtonActionType1: "close",
						modalButtonAction1: "", // empty if action type = close
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const pageName = `ASN Details: ${page_data.asn.seller + page_data.asn.asn_id}`;
	setDocumentTitle(pageName);

	const TitleHeading = () => {
		return (
			<Box>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} container spacing={3} mb={2}>
						<Grid item xs="auto">
							<Typography component="h1" variant="h5">
								{pageName}
							</Typography>
						</Grid>
						<Grid item xs />

						{(page_data.asn.status.toLowerCase() !== "cancelled") && (
							<>
								<Grid item xs="auto">
									<Button
										variant="outlined"
										color="primary"
										onClick={() => onClickMoreActionsButton()}
									>
										More Actions
									</Button>
								</Grid>

								{(page_data.asn.status.toLowerCase() !== "partiallybooked")
								&& (page_data.asn.status.toLowerCase() !== "bookedin") &&
								<Grid item xs="auto">
									<CancelASNButton />
								</Grid>}

							</>
						)}

					</Grid>
				</Grid>

			</Box>
		);
	};

	const Summary = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Summary
				</Typography>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={12} md={6} lg={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>ASN Status:</Box>
							<Box component="span">
								<StatusBox 
									sx={{ mb: 1 }}
									status={page_data.asn.status} 
									statusType="asn" 
								/>
							</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold" }}>Delivery Slot:</Box>
							<Box component="span">
								<StatusBox 
									sx={{ ml: 1, mb: 1 }} 
									status={getASNDeliverySlotStatus(page_data.asn)} 
									statusType="delivery" 
								/>
							</Box>
						</Typography>
					</Grid>
	
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Created Date:</Box>
							<Box component="span">{getDisplayTime(page_data.asn.created_date, "", "/")}</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} sx={{ mb: 1 }}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Estimated Delivery Date:</Box>
							<Box component="span">{getDisplayTime(page_data.asn.estimated_delivery, "", "/")}</Box>
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Warehouse:</Box>
							<Box component="span">{page_data.asn.warehouse}</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} sx={{ mb: 1 }}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>PO Reference:</Box>
							<Box component="span">{page_data.asn.seller_po_reference}</Box>
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Units Received:</Box>
							<Box component="span">{page_data.asn.units_received}/{page_data.asn.units_expected}</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						{page_data.asn.inbound_order_number && 
							<Typography component="div">
								<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Inbound Order Number (Warehouse):</Box>
								<Box component="span">{page_data.asn.inbound_order_number}</Box>
							</Typography>
						}
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Last Warehouse Update:</Box>
							<Box component="span">
								<LastWarehouseUpdateDetails details={page_data.asn} />
							</Box>
						</Typography>
					</Grid>
				</Grid>

			</Paper>
		);
	};

	const ManifestData = () => {
		if (typeof page_data.product.items === "object" && page_data.product.items !== null) {
			return (
				page_data.product.items.map((s, idx) => <ItemDisplay item={s} key={idx} itemType='asn' />
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="3" align="center">No items found</TableCell>
				</TableRow>
			);
		}
	};

	const ManifestTable = () => {
		return (

			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>

				<Typography component="h1" variant="h5" mb={1}>
					Manifest
				</Typography>

				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="left">SKU</TableCell>
								<TableCell align="left">Name</TableCell>
								<TableCell align="left">Expected Quantity</TableCell>
								<TableCell align="left">Quantity Received</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<ManifestData />
						</TableBody>
					</Table>
				</TableContainer>

			</Paper>

		);
	};

	const DeliveryInformation = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Delivery Information
				</Typography>
			
				<Grid container>
					<Grid item xs={12} sm={6} mb={1}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Delivery Type:</Box>
							<Box component="span">{page_data.asn.delivery_type}</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} mb={1}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Country of Origin:</Box>
							<Box component="span">{page_data.asn.country_of_origin}</Box>
						</Typography>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12} sm={6} mb={1}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Carrier:</Box>
							<Box component="span">{page_data.asn.carrier}</Box>
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} mb={1}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Tracking Information:</Box>
							<Box component="span">{page_data.asn.tracking_number}</Box>
						</Typography>
					</Grid>
				</Grid>

			</Paper>
		);
	};

	const Comments = () => {

		if (!page_data.asn.comments) return;

		return (
			<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Comments
				</Typography>
			
				<Grid container>
					<Grid item xs={12}>
						<TextField 
							fullWidth
							multiline
							variant="outlined" 
							margin="none" 
							value={page_data.asn.comments}
							InputProps={{ readOnly: true }} 
							id="comments"
							aria-describedby="asn comments"
						/>
					</Grid>
				</Grid>

			</Paper>
		);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
				modalErrorMessage: "",
				modalWarningMessage: "",
				modalButtonColor2: "primary",
			};
		});
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") return;

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});
	};

	const SnackBarAlert = () => {
		return (
			<Snackbar 
				open={state.showAlert} 
				autoHideDuration={16000} 
				onClose={onAlertHandleClose} 
			>
				<Box>
					<AlertBox
						severity={state.alertSeverity}
						message={state.alertMessage}
					/>
				</Box>
			</Snackbar>
		);
	};

	return (
		<LoadableContent isLoading={isLoading}>
			<Box id="asn-detail">
				{status ?
					<>
						<BreadCrumb
							breadcrumbs={[
								{ label: "Goods In", url: "/asn-summary" },
								{ label: page_data?.asn?.seller + page_data?.asn?.asn_id }
							]}
						/>
						<TitleHeading />
						<MoreActionsDiv />
						<Summary />
						<ManifestTable />
						<DeliveryInformation />
						<Comments />
						<DeliverySlot
							open={state.isModalOpenDeliverySlot}
							onClose={onModalCloseDeliverySlot}
							estimatedDeliveryDate={page_data.asn.estimated_delivery}
							getDeliverySlot={getDeliverySlot}
							requestType={state.ModalDeliverySlotType}
							deliveryType={page_data.asn.delivery_type}
							title={state.ModalDeliverySlotTitle}
							userdata={userdata}
							alwaysNeedSlot={false}
							warehouseName={page_data.asn.warehouse}
						/>
						<SnackBarAlert />
						<Modal
							onModalClose={onModalClose}
							isModalOpen={state.isModalOpen}
							modalBodyTitle={state.modalBodyTitle}
							modalErrorMessage={state.modalErrorMessage}
							modalBodyMessage1={state.modalBodyMessage1}
							modalBodyMessage1Data={state.modalBodyMessage1Data}
							modalBodyMessage2={state.modalBodyMessage2}
							modalBodyInputFields={state.modalBodyInputFields}
							modalButtonVariant1={state.modalButtonVariant1}
							modalButtonText1={state.modalButtonText1}
							modalButtonActionType1={state.modalButtonActionType1}
							modalButtonAction1={state.modalButtonAction1}
							modalButtonVariant2={state.modalButtonVariant2}
							modalButtonText2={state.modalButtonText2}
							modalButtonActionType2={state.modalButtonActionType2}
							modalButtonAction2={state.modalButtonAction2}
							modalButtonColor2={state.modalButtonColor2}
							warningMessage={state.modalWarningMessage}
							errorMessage={state.modalErrorMessage}
						/>					
					</>
					:
					<>
						<Typography mb={2}>
							Invalid ASN ID
						</Typography>
						<BackButton />
					</>
					
				}
			</Box>
		</LoadableContent>
	);

}