import React from "react";
import {
	Grid,
	Typography,
} from "@mui/material";

export const PageHeading = ({
	pageTitle,
	children,
	sx,
}) => {

	return (
		<Grid 
			item
			container
			xs={6}
			sm={8}
			lg={12}
			xl={12}
			mb={0}
			alignItems="center"
			sx={{ ...sx }}
		>
			<Grid item xs="auto">
				<Typography variant="h5" component="h1" mb={1}>
					{pageTitle}
				</Typography>
			</Grid>
			{children}
		</Grid>
	);
    
};
  