import React from "react";
import {
	Select,
	MenuItem,
	InputLabel,
	FormControl
} from "@mui/material";

export default function PageRowsDropDown({
	resultsTotalCount,
	paginationPostsPerPage,
	pageRowOptions,
	onPageRowChange,
}) {

	let updatedOptions = pageRowOptions.filter((option) => option <= resultsTotalCount);

	if (resultsTotalCount < pageRowOptions[pageRowOptions.length - 1]) {
		updatedOptions.push("All");
	}

	if (resultsTotalCount <= pageRowOptions[0]) {
		return null;
	}

	return (
		<FormControl sx={{ ml: 4, maxWidth: 100 }}>
			<InputLabel id="pageRows-select">Rows</InputLabel>
			<Select
				labelid="pageRows-select"
				id="pageRows"
				label="Rows"
				size="small"
				fullWidth
				value={paginationPostsPerPage}
				onChange={(e) => onPageRowChange(e.target.value)}
			>
				{updatedOptions.map((item, i) => (
					<MenuItem
						key={i}
						value={item === "All" ? resultsTotalCount : item} // Set total count for "All"
					>
						{item === "All" ? "All" : item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
