import React, { useCallback } from "react";
import "./Summary.scss";
import SellerDependentView from "../Shared/SellerDependentView";
import MediaCard from "./components/Card";
import {
	Box,
	Grid,
	Typography,
} from "@mui/material";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	getCurrentDateTruncated,
	getFormattedTime
} from "../../utils/date";
import { DataContext } from "../../App";
import { styled } from "@mui/material/styles";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function SummaryChecker({ seller }) {

	// These need to be instantiated separately as date is passed by reference
	let todaysStartDate = getCurrentDateTruncated();
	let todaysEndDate = getCurrentDateTruncated();
	let thirtyDaysAgoDate = getCurrentDateTruncated();

	todaysEndDate.setDate(todaysStartDate.getDate() + 1);
	thirtyDaysAgoDate.setDate(todaysStartDate.getDate() - 30);

	const formattedStartDate = getFormattedTime(todaysStartDate);
	const formattedThirtyDaysAgoDate = getFormattedTime(thirtyDaysAgoDate);
	const formattedEndDate = getFormattedTime(todaysEndDate);

	const context = React.useContext(DataContext);
	const disableAPI = !seller.id || seller.status.toLowerCase() === "frozen";
	
	const fetchData = (disableAPI, apiFunction, args) => {
		if (disableAPI) {
			return Promise.resolve({ results: {} });
		}
		return apiFunction(...args);
	};

	const StyledBox = styled(Box)(() => ({
		width: "100%", 
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: "rgba(118, 118, 118, 0.25)",
		borderRadius: "0.25rem",
		padding: "15px"
	}));

	const EvenlySpacedGrid = styled(Grid)(({ theme }) => ({
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			justifyContent: "space-between"
		}
	}));

	const actionsData = useFetchData(
		useCallback(
			() => fetchData(disableAPI, context.dataProvider.getSummaryActions, 
				[seller.id]), 
			[context.dataProvider, seller, disableAPI])
	);

	const orderTotalsData = useFetchData(
		useCallback(
			() => fetchData(disableAPI, context.dataProvider.getSummaryOrderTotals, 
				[seller.id, formattedStartDate, formattedEndDate]),
			[context.dataProvider, seller, formattedStartDate, formattedEndDate, disableAPI]
		)
	);
 
	const deliveryPerformanceData = useFetchData(
		useCallback(
			() => fetchData(disableAPI, context.dataProvider.getSummaryDeliveryPerformance, 
				[seller.id, formattedThirtyDaysAgoDate, formattedEndDate]),
			[context.dataProvider, seller, formattedThirtyDaysAgoDate, formattedEndDate, disableAPI]
		)
	);

	const getDeliveredOnTimePercentage = (numerator, denominator) => {
		if (denominator > 0) {
			const percentage = (numerator / denominator) * 100.0;
			return `${percentage.toFixed(2)}%`;
		}
		return "--";
	};

	const ActionsSection = () => (
		<Box className="actions-section">
			<StyledBox>

				<Typography variant="h5" component="h2" mb={2}>
					Last 30 Days Actions Required
				</Typography>

				<Grid container spacing={3} mb={1}>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							data={actionsData}
							titleText="Awaiting Confirmation Orders"
							bodyText={actionsData.results.awaiting_confirmation}
							hasImage={true}
							toolTip={"Orders in Awaiting Confirmation for the last 30 days"}
							url={"/order-status?warehouse_status=awaitingconfirmation&date_range=30"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							data={actionsData}
							titleText="On Back Orders"
							bodyText={actionsData.results.on_back_order}
							hasImage={true}
							toolTip={"Orders On Back Order for the last 30 days"}
							url={"/order-status?warehouse_status=onbackorder&date_range=30"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							data={actionsData}
							titleText="Transit Errors"
							bodyText={actionsData.results.exception_in_transit}
							hasImage={true}
							toolTip={"Orders with Transit Errors for the last 30 days"}
							url={"/order-status?courier_status=exception&date_range=30"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

				</Grid>

			</StyledBox>
		</Box>
	);

	const TodaySection = () => (
		<Box className="today-section">
			<StyledBox>

				<Typography variant="h5" component="h2" mb={2}>
					Today
				</Typography>

				<Grid container spacing={3} mb={1}>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<MediaCard
							data={orderTotalsData}
							titleText="Orders Created"
							bodyText={orderTotalsData.results.created}
							toolTip={"Orders created manually or via sales channels today"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<MediaCard
							data={orderTotalsData}
							titleText="Orders Processed To Warehouse"
							bodyText={orderTotalsData.results.processed}
							toolTip={"Orders that have been sent to the warehouse to be despatched today"}
							url={"/order-status?warehouse_status=processed&date_range=0"}
							dateRange={"Today"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<MediaCard
							data={orderTotalsData}
							titleText="Orders Despatched"
							bodyText={orderTotalsData.results.despatched}
							toolTip={"Orders that have been despatched by the warehouse today"}
							url={"/order-status?warehouse_status=despatched&date_range=0"}
							dateRange={"Today"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<MediaCard
							data={orderTotalsData}
							titleText="Orders In Transit"
							bodyText={orderTotalsData.results.in_transit}
							toolTip={"Orders that the couriers are delivering to your customers"}
							url={"/order-status?courier_status=intransit&date_range=0"}
							dateRange={"Today"}
						/>
					</Grid>

				</Grid>

			</StyledBox>
		</Box>
	);

	const Last30DaysSection = () => (
		<Box className="last-30days-section">
			<StyledBox>

				<Typography variant="h5" component="h2" mb={2}>
					Last 30 Days Performance
				</Typography>

				<EvenlySpacedGrid container spacing={3} mb={1}>

					<Grid item xs={12} sm={6} md={4} lg={2.4}>
						<MediaCard
							data={deliveryPerformanceData}
							titleText="Orders Despatched"
							bodyText={deliveryPerformanceData.results.despatched}
							toolTip={"Number of orders that have been despatched by the warehouse in the last 30 days"}
							hasImage={false}
							url={"/order-status?warehouse_status=despatched&date_range=30"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2.4}>
						<MediaCard
							data={deliveryPerformanceData}
							titleText="Orders Delivered"
							bodyText={deliveryPerformanceData.results.delivered}
							toolTip={"Number of orders that have been delivered in the last 30 days (this excludes orders on untracked delivery services)"}
							url={"/order-status?courier_status=delivered&date_range=30"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2.4}>
						<MediaCard
							data={deliveryPerformanceData}
							titleText="Orders Despatched On Time"
							bodyText={getDeliveredOnTimePercentage(deliveryPerformanceData.results.despatched_on_time, deliveryPerformanceData.results.despatched)}
							toolTip={"Percentage of orders that were despatched on time by the warehouse"}							
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2.4}>
						<MediaCard
							data={deliveryPerformanceData}
							titleText="Orders Delivered On Time"
							bodyText={getDeliveredOnTimePercentage(deliveryPerformanceData.results.delivered_on_time, deliveryPerformanceData.results.delivered)}
							toolTip={"Percentage of orders that were delivered on time by the couriers (this excludes orders on untracked delivery services)"}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={2.4}>
						<MediaCard
							data={deliveryPerformanceData}
							titleText="Late Deliveries"
							bodyText={deliveryPerformanceData.results.late_deliveries}
							toolTip={"Total number of late deliveries in the last 30 days"}
							url={"/late-deliveries"}
							dateRange={"the last 30 days"}
						/>
					</Grid>

				</EvenlySpacedGrid>

			</StyledBox>
		</Box>
	);

	return (
		<Box id="summary-checker">
			<SellerDependentView seller={seller.id}>
				<Box>
					<PageHeading pageTitle={"Welcome back"}/>
					<Typography component="div" variant="p">
						Here&apos;s a quick overview of your logistics performance
					</Typography>
					<Grid container mt={2} mb={2}>
						<Grid item xs={12}>
							<ActionsSection />
						</Grid>
						<Grid item xs={12} mt={2} mb={2}>
							<TodaySection />
						</Grid>
						<Grid item xs={12}>
							<Last30DaysSection />
						</Grid>
					</Grid>
				</Box>
			</SellerDependentView>
		</Box>
	);
}
