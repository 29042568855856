import * as Yup from "yup";

export const FORM_VALIDATION = (existingProduct = {}) =>
	Yup.object().shape({
		sku: Yup.string().when([], {
			is: () => !existingProduct.sku,
			then: () =>
				Yup.string()
					.required(
						"Enter the product's unique EAN or UPC barcode number here. If you don't have one please use your internal SKU code instead. Max 12 or 13 digits."
					)
					.matches("^[0-9]{12,13}$", "Enter a valid 12 or 13-digit barcode (EAN or UPC).")
					.test(
						"no-leading-multiple-zeros",
						"The SKU cannot start with more than one zero.",
						(value) => value == null || !/^00/.test(value)
					),
			otherwise: () => Yup.string(),
		}),
		name: Yup.string()
			.required("Enter the product's name. This should only contain letters, number, hyphens, and spaces. Max 75 characters.")
			.matches("^[a-zA-Z0-9- ]{1,150}$", "Name must only contain letters, numbers, hyphens, and spaces. Max length 75 characters."),
		description: Yup.string().required(
			"Enter a brief description of the product, including size, colour, etc..."
		),
		barcode_type: Yup.number().required("Please select a barcode type from the list"),
		barcode_number: Yup.string()
			.when("barcode_type", {
				is: (barcode_type) => barcode_type === 1,
				then: () =>
					Yup.string()
						.required("Please enter the EAN barcode number.")
						.matches("^[0-9]{13}$", "EAN barcode numbers must be 13 characters [0-9] long exactly."),
			})
			.when("barcode_type", {
				is: (barcode_type) => barcode_type === 2,
				then: () =>
					Yup.string()
						.required("Please enter the UPC barcode number.")
						.matches("^[0-9]{12}$", "UPC barcode numbers must be 12 characters [0-9] long exactly."),
			}),
	});
