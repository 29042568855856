import React, {
	useCallback,
	useState
} from "react";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { BackButton } from "../Shared/BackButton/BackButton";
import {
	Formik,
	Form
} from "formik";
import * as Yup from "yup";
import useFetchData from "../Shared/FetchData/FetchData";
import DropDown from "../Shared/DropDown";
import FileUpload from "../Shared/FileUpload/FileUpload";
import { convertToBase64String } from "../../utils/file";
import { DataContext } from "../../App";
import Modal from "../Shared/Modals/Modal";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function Claim({ userdata, seller }) {

	const context = React.useContext(DataContext);
	const location = useLocation();
	const orderNumber = location.state?.orderNumber;
	const courierService = location.state?.courierService;
	const maxFileSize = 4000000;

	const [state, setState] = useState({
		isDisabled: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalIsLoading: false,
	});

	if (!orderNumber) return <BackButton message={"Order Number not found"} link={"/order-status"}/>;

	const orderClaimTypesResponse = useFetchData(useCallback(() => context.dataProvider.getOrderClaimTypes(), [context.dataProvider]));
	const orderClaimTypes = orderClaimTypesResponse.status === true && orderClaimTypesResponse.results ? orderClaimTypesResponse.results : []; 

	const getInitialFormState = () => {
		const initialValues = {
			claim_type: null,
			selectedClaimTypeId: "",
		};
		orderClaimTypes.forEach(claimType => {
			claimType.document_types.forEach(docType => {
				initialValues[`file-${docType.id}`] = [];
			});
		});

		return initialValues;
	};
 
	const INITIAL_FORM_STATE = getInitialFormState();
	
	const validateForm = (values) => {
		const validationSchema = Yup.object().shape({
			claim_type: Yup.number()
				.nullable()
				.required("Please select a claim type"),
			selectedClaimTypeId: Yup.number()
				.nullable()
				.required("A claim type must be selected"),
			...orderClaimTypes.find(claimType => claimType.id === values.selectedClaimTypeId)?.document_types.reduce((accumulator, docType) => {
				const fieldName = `file-${values.selectedClaimTypeId}-${docType.id}`;
				accumulator[fieldName] = Yup.mixed().required(docType.description);
				return accumulator;
			}, {})
		});
	
		try {
			validationSchema.validateSync(values, { abortEarly: false });
			return {};
		} catch (err) {
			return err.inner.reduce((errors, error) => {
				errors[error.path] = error.message;
				return errors;
			}, {});
		}
	}; 
	
	const handleClaimTypeChange = (setFieldValue, e) => {
		const newClaimTypeId = e ? Number(e) : null;
		setFieldValue("selectedClaimTypeId", newClaimTypeId);
		
		// Reset all file upload fields
		orderClaimTypes.forEach(claimType => {
			claimType.document_types.forEach(docType => {
				const uniqueKey = `file-${claimType.id}-${docType.id}`;
				setFieldValue(uniqueKey, null);
			});
		});
	};
	
	const FormComponents = ({ setFieldValue, values, errors }) => {

		const selectedClaimType = orderClaimTypes.find(claimType => claimType.id === values.selectedClaimTypeId);
		
		return (
			<>
				<Box>
					<DropDown
						id="claim_type"
						name="claim_type"
						label="Claim Type"
						value={values.selectedClaimTypeId}
						onChange={(e) => handleClaimTypeChange(setFieldValue, e)} 
						data={orderClaimTypes.map((claimType) => ({
							key: claimType.id,
							value: claimType.id,
							name: claimType.label
						}))}
						required={true}
					/>
				</Box>

				{selectedClaimType && selectedClaimType.document_types.map((docType) => (
					<FileUpload
						key={`file-upload-${selectedClaimType.id}-${docType.id}`}
						name={`file-${selectedClaimType.id}-${docType.id}`}
						label={docType.label}
						accept={docType.formats.toString()}
						multiple={docType.name === "photo_evidence"}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
					/>
				))}
				
				<Box className="align-content-right">
					<Button
						type="submit"
						variant="contained"
						disabled={state.isDisabled}
					>
						Submit Claim
					</Button>
				</Box>
			</>
		);
	};
	
	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyTitle: "",
				modalBodyMessage1: "",
				modalBodyMessage2: "",
			};
		});
	};

	const onSubmitForm = async (values) => {
		setState(previousState => {
			return {
				...previousState,
				isDisabled: true,
			};
		});

		// Fetch file types data
		const fileTypesData = await context.dataProvider.getFileTypes();
	
		// Create a mapping of file extensions to IDs
		const fileTypesMapping = fileTypesData.data.reduce((accumulator, fileType) => {
			const extension = fileType.name.split("/")[1]; 
			accumulator[extension] = fileType.id;
			return accumulator;
		}, {});

		if (fileTypesMapping["jpeg"]) {
			fileTypesMapping["jpg"] = fileTypesMapping["jpeg"];
		}
	
		let fileDocuments = [];
	
		const selectedClaimType = orderClaimTypes.find(claimType => claimType.id === values.selectedClaimTypeId);
		const fileReadPromises = selectedClaimType?.document_types.flatMap(docType => {
			const fieldName = `file-${selectedClaimType.id}-${docType.id}`;
			let files = values[fieldName];

			if (!Array.isArray(files)) {
				files = [files];
			}

			return files.map(file => {
				if (file) {
					if (file.size > maxFileSize) {
						setState(previousState => {
							return {
								...previousState,
								isModalOpen: true,
								modalIsLoading: false,
								modalBodyTitle: "Supporting file is too large",
								modalBodyMessage1: file.name + " should be no larger than 4MB in size",
								modalBodyMessage2: "Please try a different file, or first reduce the size of the file you're trying to upload",
								modalButtonVariant1: "contained",
								modalButtonText1: "Close",
								modalButtonActionType1: "close",
								modalButtonAction1: "",
								isDisabled: false,
							};
						});

						return Promise.resolve();
					}

					let fileExtension = file.name.split(".").pop().toLowerCase();
					const fileTypeId = fileTypesMapping[fileExtension];

					return convertToBase64String(file).then(async (base64) => {
						// Call the updateFile API
						const response = await context.dataProvider.updateFile(seller, file.name, fileTypeId, base64);
						const responseData = response.data;

						// Save the file_id and the document type id
						if (responseData && responseData.file_id) {
							fileDocuments.push({ file_id: responseData.file_id, doc_type_id: docType.id });
						}
					});
				}
				return Promise.resolve();
			});
		}) || [];
				
		await Promise.all(fileReadPromises);

		if (fileDocuments.length === 0) {
			return;
		}
	
		const getClaimData = () => {
			const claimData = {
				"type_id": values.selectedClaimTypeId,
				"order_number": orderNumber,
				"courier_service": courierService,
				"client_id": seller,
				"documents": fileDocuments.map(d => ({
					"type_id": d.doc_type_id,
					"file_id": d.file_id
				}))
			};
			
			return claimData;
		};

		const createOrderClaim = async (orderClaim) => {
			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
					modalIsLoading: true,
				};
			});

			await context.dataProvider.createOrderClaim(seller, orderClaim)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Claim successfully created",
							modalBodyMessage1: `Claim for Order ${orderNumber} has successfully been created.`,
							modalBodyMessage2: "Someone from our customer service team will review it and respond shortly.",
							modalButtonVariant1: "contained",
							modalButtonText1: "Return to Order Summary",
							modalButtonActionType1: "link",
							modalButtonAction1: "/order-status",
							isDisabled: true,
						};
					});
				})
				.catch(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Claim was unsuccessful",
							modalBodyMessage1: "Something went wrong.",
							modalBodyMessage2: "Please try creating the claim again or contact support@bezos.ai",
							modalButtonVariant1: "contained",
							modalButtonText1: "Return to Order",
							modalButtonActionType1: "close",
							modalButtonAction1: "",
							isDisabled: false,
						};
					});
				});

			setState(previousState => {
				return {
					...previousState,
					modalIsLoading: false,
				};
			});

		};

		const claimData = getClaimData();

		if (claimData && claimData.type_id && claimData.order_number && claimData.client_id && claimData.documents && claimData.documents.length > 0) {
			await createOrderClaim(claimData);
		} else {
			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "Claim creation was unsuccessful",
					modalBodyMessage1: "Invalid claim data, cannot proceed with creating order claim.",
					modalBodyMessage2: "Please contact support@bezos.ai",
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to Order",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
					isModalOpen: true,
					isDisabled: false,
				};
			});
		}

	};

	const ClaimForm = () => 
		<Formik
			initialValues={INITIAL_FORM_STATE}
			validate={validateForm}
			onSubmit={(values) => onSubmitForm(values)}
			enableReinitialize
			validateOnChange={true}
			validateOnBlur={true}
		>
			{({ setFieldValue, values, errors }) => (
				<Form
					noValidate
					autoComplete="off"
				>
					<FormComponents 
						values={values} 
						errors={errors}
						setFieldValue={setFieldValue} 
					/>
				</Form>
			)}
		</Formik>
	;

	const PageBody = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Grid item xs={12}>
				<Grid item xs="auto">
					<Typography component="h4" variant="h6">
						Order Number: {orderNumber}
					</Typography>
				</Grid>
				<Grid item xs="auto">
					<ClaimForm/>
				</Grid>
			</Grid>
		</Paper>
	);

	return (
		<Box id="claim">
			<PermissionDependentView userdata={userdata} permission="create_order_claim">
				<Grid container>
					<BreadCrumb 
						breadcrumbs={[
							{ label: "Orders", url: "/order-status" },
							{ label: orderNumber, url: `/order-view/${orderNumber}` },
							{ label: "Raise A Claim" }
						]}
					/>
					<PageHeading pageTitle={"Raise A Claim"} sx={{ mt: 1 }}/>
					<PageBody/>
					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalBodyMessage1Data={state.modalBodyMessage1Data}
						modalBodyMessage2={state.modalBodyMessage2}
						modalBodyInputFields={state.modalBodyInputFields}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
						modalIsLoading={state.modalIsLoading}
					/>
				</Grid>
			</PermissionDependentView>
		</Box>
	);
}