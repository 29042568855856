import React from "react";
import {
	Form,
	Formik
} from "formik";
import DropDown from "../../Shared/DropDown";
import TextFieldComp from "../../GoodsInCreate/components/TextField";
import {
	Box,
	Button
} from "@mui/material";
import { getProductBarcodeTypes } from "../../../utils/barcodeTypes";
import { FORM_VALIDATION } from "./productValidationSchema";

export function ProductDetails({
	onProductDetailsFormSubmit,
	onPanelsVisibleChange,
	existingProduct
}) {

	const validationSchema = FORM_VALIDATION(existingProduct);
	const page_data = {
		barcode_types: getProductBarcodeTypes()
	};

	const INITIAL_FORM_STATE = {
		sku: existingProduct.sku || "",
		name: existingProduct.name || "",
		description: existingProduct.description || "",
		barcode_type: existingProduct.barcode_type || 0,
		barcode_number: existingProduct.barcode_number || "",
	};

	const onSubmitForm = (values) => {
		onProductDetailsFormSubmit(values);
		onPanelsVisibleChange({
			isProductDetailsPanelVisible: false,
			isProductDimensionsPanelVisible: true,
			isExtraDetailsPanelVisible: false,
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values }) => (
					<Form
						noValidate
						autoComplete="off"
					>
						<TextFieldComp
							name="sku"
							label="SKU Code"
							disabled={existingProduct.sku ? true : false}
							helperText="Enter the product's unique EAN or UPC barcode number here. If you don't have one please use your internal SKU code instead. Max 12 or 13-digits."
							required
						/>

						<TextFieldComp
							name="name"
							label="Product Name"
							helperText="Enter the product's name. This should only contain letters, number, hypens, and spaces. Max 75 characters."
							required
						/>

						<TextFieldComp
							name="description"
							label="Product Description"
							helperText="Enter a brief description of the product, including size, colour, etc..."
							required
						/>

						<DropDown
							id="barcode_type"
							name="barcode_type"
							label="Barcode Type"
							value={values.barcode_type}
							data={page_data.barcode_types.map((bt) => ({
								key: bt.id,
								value: bt.id,
								name: bt.name
							}))}
							required={true}
						/>

						<TextFieldComp
							name="barcode_number"
							label="Barcode Number"
							helperText="If your product uses a UPC barcode (12 digits) or EAN barcode (13 digits) input this here."
						/>

						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
}