import React, {
	useEffect,
	useRef,
	useState
} from "react";
import {
	Box,
	Button,
	LinearProgress
} from "@mui/material";
import {
	Form,
	Formik
} from "formik";
import * as Yup from "yup";
import TextFieldComp from "../../components/TextField";
import Modal from "../../../Shared/Modals/Modal";
import {DataContext} from "../../../../App";
import {useNavigate} from "react-router-dom";
import {AlertBox} from "../../../Shared/Alert/Alert";
import {DeliverySlot} from "../../../Shared/DeliverySlot/DeliverySlot";
import {
	convertToISO,
	getDisplayDate
} from "../../../../utils/date";

export default function PackagingConfiguration({
	sellerID,
	shippingDetailsForm,
	addProductsForm,
	isASNDraft,
	packagingFormData,
	countries,
	onPanelsVisibleChange,
	userdata,
	warehouseID,
	warehouseName,
}) {

	const context = React.useContext(DataContext);
	const formikRef = useRef(null);
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [saveAsDraft, setSaveAsDraft] = useState(false);


	const estimatedDeliveryDate = shippingDetailsForm["estimated_delivery_date"];
	const deliveryType = shippingDetailsForm["delivery_type"];

	const INITIAL_FORM_STATE = {
		mixed_sku_cartons: packagingFormData.mixed_sku_cartons || 0,
		single_sku_cartons: packagingFormData.single_sku_cartons || 0,
		comments: packagingFormData.comments || "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		mixed_sku_cartons: Yup.number()
			.required("Quantity required"),
		single_sku_cartons: Yup.number()
			.required("Quantity required")
	});

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalWarningMessage: null,
		modalErrorMessage: null,
		isModalOpenDeliverySlot: false,
		isDeliverySlotBooked: null,
		isCustomsCleared: false,
		estimatedDeliveryDate: estimatedDeliveryDate,
		deliverySlotRequirements: {},
	});

	useEffect(() => {

		if (!warehouseID || typeof warehouseID !== "number") return null;

		const getDeliverySlotRequirements = async () => {
			try {
				const response = await context.dataProvider.getDeliverySlotRequirements(warehouseID);
				setState(previousState => {
					return {
						...previousState,
						deliverySlotRequirements: response.data,
					};
				});
			} catch (error) {
				setState(previousState => {
					return {
						...previousState,
						deliverySlotRequirements: {},
					};
				});
			}
		};
		getDeliverySlotRequirements();
	}, [warehouseID]);

	const createASN = async (endpointToCall, asn) => {

		try {

			setError("");
			setLoading(true);
			setFormSubmitted(true);

			const response = await endpointToCall(sellerID, asn);

			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "ASN Created",
					modalBodyMessage1: "You have successfully created ASN number: " + response.data,
				};
			});

			if (state.isDeliverySlotBooked === true && saveAsDraft === false) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN Created and Delivery Slot Requested",
						modalBodyMessage1: "You have successfully created ASN " + response.data + " and requested a delivery slot for " + getDisplayDate(state.estimatedDeliveryDate) + ". We will confirm via email when the delivery slot has been booked in.",
					};
				});
			}

			if (state.isDeliverySlotBooked === false && saveAsDraft === false) {
				setState(previousState => {
					return {
						...previousState,
						modalWarningMessage: "Please make sure a delivery slot is requested for this shipment, this can be done via the ASN details page.",
					};
				});
			}

			if (saveAsDraft === true) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "ASN saved as draft",
						modalBodyMessage1: "This ASN (" + response.data + ") has been saved as a draft. As a result it has not been sent to the warehouse yet and can be edited at anytime.",
					};
				});
			}

			setState(previousState => {
				return {
					...previousState,
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to ASN Summary",
					modalButtonActionType1: "link",
					modalButtonAction1: "/asn-summary",
					isModalOpen: true
				};
			});

			if (!saveAsDraft) successPageRedirect();
		} catch (error) {

			let headerMessage = "ASN creation unsuccessful";
			let bodyMessage1 = "ASN creation failed. Please try creating the ASN again. If the problem continues email support@bezos.ai";

			if (saveAsDraft === true) {
				headerMessage = "ASN saving as draft unsuccessful";
				bodyMessage1 = "Please try saving the ASN as a draft again. If the problem continues email support@bezos.ai";
			}

			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: headerMessage,
					modalWarningMessage: null,
					modalErrorMessage: error !== "" ? error : "Something went wrong.",
					modalBodyMessage2: bodyMessage1,
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to ASN details",
					modalButtonActionType1: "close",
					modalButtonAction1: "", // empty if action type = close
					isModalOpen: true
				};
			});
		}
		setLoading(false);
	};

	const successPageRedirect = () => {
		const timer = setTimeout(() => {
			navigate("/asn-summary");
		}, 5000);
		return () => clearTimeout(timer);
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: false,
			isAddProductsPanelVisible: true,
			isAdditionalDetailsPanelVisible: false,
		});
	};

	const onModalCloseDeliverySlot = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpenDeliverySlot: false,
			};
		});
	};

	const onSubmitForm = (values) => {

		setError("");
		values.is_draft = saveAsDraft;
		values.asn_items = addProductsForm;

		const countryOfOrigin = countries.results.find(c => c.platform_id === shippingDetailsForm.country_of_origin);

		if (countryOfOrigin) {
			shippingDetailsForm.country_of_origin = countryOfOrigin.code;
		} else {
			shippingDetailsForm.country_of_origin = "";
		}

		let asn = Object.assign(shippingDetailsForm, values);

		const quantityMismatch = (asn.mixed_sku_cartons + asn.single_sku_cartons !== asn.delivery_quantity);
		if (asn.delivery_type === "Carton" && quantityMismatch) {
			setError("Mixed/single SKU carton quantities do not sum to the delivery quantity.");
			setFormSubmitted(true);
			return;
		}

		const isNeedDeliverySlot = (myState, asn) => {
			const reqs = myState.deliverySlotRequirements;
			if (reqs.always_need_delivery_slot) {
				return true;
			}
			if (asn.delivery_type === "Carton" && asn.delivery_quantity >= reqs.carton_threshold) {
				return true;
			}
			if (asn.delivery_type === "Pallet" && asn.delivery_quantity >= reqs.pallet_threshold) {
				return true;
			}
			return asn.delivery_type.includes("Container") && asn.delivery_quantity >= reqs.container_threshold;
		};

		// Validate we have the delivery slot requirements.  We either get a result for our specific warehouse or a default
		// that says delivery slot always needed.
		const validateDeliverySlot = (reqs) => {
			return reqs.always_need_delivery_slot || reqs.warehouse_id !== 0;
		};

		if (!validateDeliverySlot(state.deliverySlotRequirements)) {
			setError("Error checking delivery slot requirements");
			setFormSubmitted(true);
			return;
		}

		if ((values.is_draft === false) &&
			(state.isDeliverySlotBooked === null) &&
			isNeedDeliverySlot(state, asn)) {
			setState(previousState => {
				return {
					...previousState,
					isModalOpenDeliverySlot: true,
				};
			});
			return;
		}

		if (state.isDeliverySlotBooked === true) {
			asn["estimated_delivery_date"] = convertToISO(state.estimatedDeliveryDate);
			asn["is_customs_cleared"] = state.isCustomsCleared;
			asn["delivery_slot_requested"] = true;
		}

		if (state.isDeliverySlotBooked === false) {
			asn["delivery_slot_requested"] = false;
		}

		let endpointToCall = "";
		if (isASNDraft === false) {
			endpointToCall = context.dataProvider.createSellerASN;
		} else {
			values.asn_id = Number(packagingFormData.asn_id);
			asn = Object.assign(shippingDetailsForm, values);
			endpointToCall = context.dataProvider.updateSellerASN;
		}

		return createASN(endpointToCall, asn);

	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				isModalOpenDeliverySlot: false,
			};
		});
	};

	const submitForm = (formikRef) => {
		if (formikRef.current) {
			formikRef.current.submitForm();
		}
	};

	const getDeliverySlot = (values) => {
		if (values.delivery_slot === "" || values.estimated_delivery_date === "") return;
		onModalCloseDeliverySlot();

		setState(previousState => {
			return {
				...previousState,
				isDeliverySlotBooked: values.delivery_slot === "Yes",
				isCustomsCleared: values.is_customs_cleared === "true",
				estimatedDeliveryDate: values.estimated_delivery_date,
			};
		});

		setSaveAsDraft(false);
		submitForm(formikRef);
	};

	return (
		<>
			<Formik
				innerRef={formikRef}
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				<Form
					noValidate
					autoComplete="off"
				>
					<TextFieldComp
						name="single_sku_cartons"
						label="Number of single SKU cartons"
						type="number"
						required
						InputProps={{
							inputProps: {
								min: 0,
							}
						}}
					/>

					<TextFieldComp
						name="mixed_sku_cartons"
						label="Number of mixed SKU cartons"
						type="number"
						required
						InputProps={{
							inputProps: {
								min: 0,
							}
						}}
					/>

					<TextFieldComp
						name="comments"
						label="Comments"
						multiline
						rows={3}
					/>

					{loading ?
						(
							<Box mt={5} mb={5} sx={{width: "100%"}}>
								<LinearProgress/>
							</Box>
						)
						:
						(formSubmitted === true && error !== "") ?
							(
								<Box mt={2} mb={5} sx={{width: "100%"}}>
									<AlertBox severity="error" message={error}/>
								</Box>
							)
							: null
					}

					<Box className="align-content-right">
						<Button
							variant="outlined"
							color="secondary"
							className="back-button"
							onClick={() => handleBackButton()}
							disabled={formSubmitted && !error}
						>
							Back
						</Button>

						<Button
							type="submit"
							variant="outlined"
							color="secondary"
							className="back-button"
							disabled={formSubmitted && !error}
							onClick={() => setSaveAsDraft(true)}
						>
							Save ASN as Draft
						</Button>

						<Button
							type="submit"
							variant="contained"
							disabled={formSubmitted && !error}
							onClick={() => setSaveAsDraft(false)}
						>
							Create ASN
						</Button>
					</Box>

					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalBodyMessage1Data={state.modalBodyMessage1Data}
						modalBodyMessage2={state.modalBodyMessage2}
						modalBodyInputFields={state.modalBodyInputFields}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
						warningMessage={state.modalWarningMessage}
						errorMessage={state.modalErrorMessage}
					/>
				</Form>
			</Formik>

			<DeliverySlot
				open={state.isModalOpenDeliverySlot}
				onClose={onModalCloseDeliverySlot}
				estimatedDeliveryDate={estimatedDeliveryDate}
				getDeliverySlot={getDeliverySlot}
				requestType={"create"}
				deliveryType={deliveryType}
				title={"Book a Delivery Slot"}
				userdata={userdata}
				alwaysNeedSlot={state.deliverySlotRequirements.always_need_delivery_slot}
				warehouseName={warehouseName}
			/>

		</>
	);
}