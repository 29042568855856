export const getUserPermissions = (userdata, permissionMap) => {
	let permissions = new Set();
	const userPermissions = userdata.permissions || new Set();
  
	Object.keys(permissionMap).forEach((key) => {
		if (userPermissions.has(key)) {
			permissions.add(permissionMap[key]);
		}
	});
  
	return permissions;
};
